import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  comp = "comp",
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleRollingToBalance = () => {
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="depositModal modal fade in"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="dep_with_modal rev_modal modal-dialog">
                <div className="header">
                  <p>
                    <img src="images/icon/crown.png" /> 콤프
                  </p>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                </div>
                <div className="modal_body">
                  <div className="board_head">
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.deposit);
                      }}
                    >
                      입금신청
                    </button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw);
                      }}
                    >
                      출금신청
                    </button>
                    <button
                      style={{ width: "120px" }}
                      onClick={() => {
                        this.props.handleChange(popupView.point);
                      }}
                    >
                      금고
                    </button>
                    <button style={{ width: "120px" }} className="active">
                      콤프
                    </button>
                  </div>

                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 콤프머니
                      </p>
                    </div>
                    <div>
                      <p>{ConverMoeny(this.props.user.rolling)} 원</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 아이디
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        value={this.props.user.id}
                        readOnly
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 예금주명
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        readOnly
                        value={this.props.user.bankowner}
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 회원은행
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        readOnly
                        value={this.props.user.bankname}
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 신청금액
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        name="deposit_amount"
                        type="text"
                        readOnly
                        placeholder="0"
                        value={this.state.balance}
                        onFocus={() => this.setState({ balance: "" })}
                        onChange={(e) =>
                          this.setState({
                            balance: e.target.value,
                          })
                        }
                      />
                      <ul
                        className="parsley-errors-list"
                        id="parsley-id-8236"
                      ></ul>
                      <div className="btn_grp" style={{ marginTop: "22px" }}>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 10000}`,
                            });
                          }}
                        >
                          1만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 50000}`,
                            });
                          }}
                        >
                          5만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 100000}`,
                            });
                          }}
                        >
                          10만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 500000}`,
                            });
                          }}
                        >
                          50만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${
                                Number(this.state.balance) + 1000000
                              }`,
                            });
                          }}
                        >
                          100만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({ balance: "0" });
                          }}
                        >
                          정정하기
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal_btn_grp"
                    style={{
                      marginTop: "10px",
                      marginLeft: "0px",
                      float: "revert",
                    }}
                  >
                    <button
                      type="button"
                      onClick={this.handleRollingToBalance}
                      style={{ backgroundColor: "#db971b", float: "revert" }}
                    >
                      <img
                        src="images/icon/modal_check.png"
                        alt="deposit-logo"
                      />
                      &nbsp;콤프전환
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
