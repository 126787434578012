import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
  code : number;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  comp = "comp",
  reg = "reg",
  login = "login",
}

export class Deposit extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" , code : new Date().getTime() };

    console.log(this.props.user);
  }

  handleAsk = () => {
    this.balanceService.askToAccount().then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "계좌문의에 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "입금",
          message: " 로그인후 이용해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
      }
    });
  };

  handleDoDeposit = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "입금",
        message: "입금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) < 30000) {
      confirmAlert({
        title: "입금",
        message: "최소 입금금액은 삼만원입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "입금",
        message: "입금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserDeposit(this.state.balance, this.state.code).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "입금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "입금",
          message: "대기중인 입금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "입금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="depositModal modal fade in"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="dep_with_modal rev_modal modal-dialog">
                <div className="header">
                  <p>
                    <img src="images/icon/crown.png" /> 입금신청
                  </p>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                </div>
                <div className="modal_body">
                  <div className="board_head">
                    <button className="active">입금신청</button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw);
                      }}
                    >
                      출금신청
                    </button>
                    <button
                      style={{ width: "120px" }}
                      onClick={() => {
                        this.props.handleChange(popupView.point);
                      }}
                    >
                      금고
                    </button>
                    <button
                      style={{ width: "120px" }}
                      onClick={() => {
                        this.props.handleChange(popupView.comp);
                      }}
                    >
                      콤프
                    </button>
                  </div>

                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 아이디
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        value={this.props.user.id}
                        readOnly
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 예금주명
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        readOnly
                        value={this.props.user.bankowner}
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 회원은행
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        readOnly
                        value={this.props.user.bankname}
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 신청금액
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        name="deposit_amount"
                        type="text"
                        readOnly
                        placeholder="0"
                        value={this.state.balance}
                        onFocus={() => this.setState({ balance: "" })}
                        onChange={(e) =>
                          this.setState({
                            balance: e.target.value,
                          })
                        }
                      />
                      <ul
                        className="parsley-errors-list"
                        id="parsley-id-8236"
                      ></ul>
                      <div className="btn_grp" style={{ marginTop: "22px" }}>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 10000}`,
                            });
                          }}
                        >
                          1만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 50000}`,
                            });
                          }}
                        >
                          5만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 100000}`,
                            });
                          }}
                        >
                          10만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${Number(this.state.balance) + 500000}`,
                            });
                          }}
                        >
                          50만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              balance: `${
                                Number(this.state.balance) + 1000000
                              }`,
                            });
                          }}
                        >
                          100만
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({ balance: "0" });
                          }}
                        >
                          정정하기
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="deposit-notice-right">
                      <p>&nbsp;</p>
                    </div>
                    <div>
                      <span
                        className="deposit-notice"
                        style={{ fontSize: "13px", textAlign: "left" }}
                      >
                        <span style={{ color: "#ff00f6" }}>
                          * 보이스피싱 및 3자사기로 협박하더라도 협상 및 타협
                          절대없음 *
                        </span>
                        <br />
                        - 계좌거래시 입금/출금 이름이 동일해야 입출금이
                        가능하오니 정확히 입력해 주세요.
                        <br />- 수표나 타인계좌로 이름변경하여 입금시 머니몰수
                        및 아이디정지 처리되오니 타인명의로 입금해야될시 문의를
                        먼저하시기 바랍니다.
                      </span>
                    </div>
                  </div>
                  <div className="modal_btn_grp" style={{ marginTop: "10px" }}>
                    <button type="button" onClick={this.handleDoDeposit}>
                      <img
                        src="images/icon/modal_check.png"
                        alt="deposit-logo"
                      />
                      &nbsp;입금신청
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleAsk()}
                      style={{ width: "150px" }}
                    >
                      <img
                        src="images/icon/modal_check.png"
                        alt="deposit-logo"
                      />
                      &nbsp;입금계좌문의
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
