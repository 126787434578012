import React, { Component } from "react";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

interface Props {}
interface State {
  notices: any;
  deposit: any;
  widhdraws: any;
  yesterdayStr: string;
}

export class Footer extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      notices: [],
      deposit: [],
      widhdraws: [],
      yesterdayStr: "",
    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var monthStr =
      yesterday.getMonth() + 1 < 10
        ? "0" + (yesterday.getMonth() + 1)
        : yesterday.getMonth() + 1;
    var dayStr =
      yesterday.getDate() < 10
        ? "0" + yesterday.getDate()
        : yesterday.getDate();
    var yesterdayStr = monthStr + "-" + dayStr;

    this.setState({ yesterdayStr: yesterdayStr });
  }

  RenderNotice = () => {
    let notices: any = [];

    this.state.notices.map((info: any, index: any) => {
      if (index < 5) {
        notices.push(info);
      }
    });

    return (
      <>
        {notices.map((info: any, index: any) => (
          <tr>
            <td></td>
            <td>[ {info.title} ]</td>
            <td>{ConvertDate(info.regdate)}</td>
          </tr>
        ))}
      </>
    );
  };

  RenderWidhdraws = () => {
    return (
      <>
        {this.state.widhdraws.map((info: any, index: any) => (
          <li className="tr">
            <div className="user">
              <span className="bullet"></span>
              <span>{info.id}</span>
            </div>
            <div className="amount">
              <span>{info.price}</span>
            </div>
            <div className="date">
              <span>{info.date}</span>
            </div>
          </li>
        ))}
      </>
    );
  };

  render() {
    return (
      <>
        <div className="main_board">
          <div className="mb_cont">
            <div className="not_eve_cont">
              <div className="head">
                <span>
                  <img src="images/partner.png" alt="" /> 파트너 제휴문의
                </span>
                <button type="button">
                  더보기 <img src="images/more.png" alt="" />
                </button>
              </div>
              <div className="partner">
                <div>
                  <span>
                    <img src="images/talk.png" alt="" />
                    {/* &nbsp;:&nbsp;onlinecasino22 */}
                  </span>
                </div>
                <div>
                  <span>
                    <img src="images/24.png" alt="" />
                    {/* &nbsp;:&nbsp; */}
                  </span>
                </div>
              </div>
            </div>
            <div className="not_eve_cont">
              <div className="head">
                <span>
                  <img src="images/notice.png" alt="notice-logo" />
                  &nbsp;공지사항&nbsp;
                </span>
                <button type="button">
                  더보기 <img src="images/more.png" alt="" />
                </button>
              </div>
              <table>{this.RenderNotice()}</table>
            </div>

            <div className="bank_cont noteve">
              <div className="header">
                <span>
                  <img src="images/bank.png" alt="" /> 입출금현황
                </span>
                <div>
                  <button className="active">입금</button>
                  <button>출금</button>
                </div>
              </div>
              <div className="bank_tab">
                <div
                  className="deposit_carousel carousel"
                  data-ride="carousel"
                  data-interval="4000"
                >
                  <div className="carousel-inner">
                    <div className="item active">
                      <table>
                        <tbody>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 00:15
                            </td>
                            <td>xlqnfh***</td>
                            <td>1,500,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 23:49
                            </td>
                            <td>vkfvkf***</td>
                            <td>200,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 23:37
                            </td>
                            <td>dasol***</td>
                            <td>30,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 23:37
                            </td>
                            <td>kille***</td>
                            <td>50,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 23:30
                            </td>
                            <td>vkfvkf***</td>
                            <td>200,000 원</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="item">
                      <table>
                        <tbody>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 23:17
                            </td>
                            <td>nan***</td>
                            <td>900,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 23:13
                            </td>
                            <td>etvzf***</td>
                            <td>3,000,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 22:32
                            </td>
                            <td>phj97***</td>
                            <td>100,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 22:26
                            </td>
                            <td>kille***</td>
                            <td>100,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/deposit.png" alt="" />
                              {this.state.yesterdayStr} 22:19
                            </td>
                            <td>phj97***</td>
                            <td>100,000 원</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bank_tab">
                <div
                  className="deposit_carousel carousel"
                  data-ride="carousel"
                  data-interval="4000"
                >
                  <div className="carousel-inner">
                    <div className="item active">
                      <table>
                        <tbody>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 23:34
                            </td>
                            <td>kille***</td>
                            <td>50,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 23:17
                            </td>
                            <td>xlqnfh***</td>
                            <td>1,080,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 23:14
                            </td>
                            <td>frxuz***</td>
                            <td>4,100,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 22:35
                            </td>
                            <td>gskim***</td>
                            <td>1,300,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 22:04
                            </td>
                            <td>vkfvkf***</td>
                            <td>1,000,000 원</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="item">
                      <table>
                        <tbody>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 21:57
                            </td>
                            <td>wo***</td>
                            <td>1,550,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 21:44
                            </td>
                            <td>godc***</td>
                            <td>800,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 20:57
                            </td>
                            <td>qk***</td>
                            <td>1,000,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 20:50
                            </td>
                            <td>blue***</td>
                            <td>1,710,000 원</td>
                          </tr>
                          <tr className="list">
                            <td>
                              <img src="images/icon/withdraw.png" alt="" />
                              {this.state.yesterdayStr} 20:27
                            </td>
                            <td>oqrxl***</td>
                            <td>12,000,000 원</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main_footer">
          <div className="mf_cont">
            <div className="contact">
              <div>
                <img src="images/tele.png" alt="" />
                <img src="images/wechat.png" alt="" />
                <img src="images/kakao.png" alt="" />
                <img src="images/skype.png" alt="" />
              </div>
              <div>
                <p>
                  <span>빠른상담&nbsp;:</span>&nbsp;
                </p>
              </div>
            </div>
            <p className="footer_text">
              <span>Copyright ONLINE CASINO © 2018</span> The Live casino Slot
              machine game website
            </p>
            <img
              className="footer_deco"
              src="images/footer_deco.png"
              alt="footer-logo"
            />
          </div>
        </div>
      </>
    );
  }
}
