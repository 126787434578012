import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Companylist } from "./companylist";
import { ConvertDate } from "../utility/help";
import Marquee from "react-fast-marquee";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
  gameView: string;
  SetCookie: (name: string, data: any) => any;
  updateContentView: (view: string) => any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
  gameEName: any;
  notices: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
      gameEName: "",
      notices: [],
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(30000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
          if (casiono.nameEn !== "Evolution") {
            item.push(casiono);
          }
        }

        this.setState({
          casionos: item,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
        this.props.user.balance = data.balance ?? 0;
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses: popupView.note,
          });
        }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  RenderSlotList = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => (
          <div
            className="dd_row"
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated === false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          mode: Mode.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.setState({ gameCode: info.code });
                this.setState({ gameName: info.nameKo });
                this.setState({ gameEName: info.nameEn });
                this.setState({ mode: Mode.slot });
                // this.handleSlotGames(info.code);
                this.setState({ popupStatuses: popupView.game });
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div></div>
            <div>
              <p>{info.nameKo}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  RenderCasinoGame = () => {
    return (
      <>
        {this.state.casionos.map((info: any, index: any) => (
          <div
            className="dd_row"
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          popupStatuses: popupView.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.handleOpenSlot(info.code, info.gameCompany);
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div></div>
            <div>
              <p>{info.nameKo}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            handleClose={this.handleClosePopup}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }

      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
            gameEName={this.state.gameEName}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      if (0 < this.state.postCount) {
        this.setState({ popupStatuses: popupView.note });
      }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="login_pan">
              <input
                type="text"
                placeholder="아이디"
                onChange={({ target: { value } }) =>
                  this.setState({ ID: value })
                }
              />
              <input
                type="password"
                placeholder="비밀번호"
                onChange={({ target: { value } }) =>
                  this.setState({ PW: value })
                }
              />
              <button
                type="submit"
                className="btn_sign_in"
                onClick={() => {
                  if (
                    this.state.ID == null ||
                    this.state.ID == "" ||
                    this.state.PW == "" ||
                    this.state.PW == null
                  ) {
                    return;
                  }
                  this.props.tryLogin(this.state.ID, this.state.PW);
                }}
              >
                로그인
              </button>
              <button
                type="button"
                className="btn_sign_up"
                onClick={() => {
                  this.setState({ popupStatuses: popupView.reg });
                }}
              >
                회원가입
              </button>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="after_login">
            <div
              className="user_cont"
              data-toggle="modal"
              data-target=".myPageModal"
              onClick={() => {
                this.setState({ popupStatuses: popupView.user }); //마이페이지
              }}
            >
              <img src="images/icon/info_user_icon.png" />
              <p>{this.props.user.id}</p>
              <span>님</span>
            </div>
            <div className="coins_cont">
              <p>보유머니:</p>
              <p className="player-balance">
                {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
              </p>
            </div>
            <div className="coins_cont">
              <p>금고머니:</p>
              <p className="player-balance">
                {ConverMoeny(this.props.user.point)}
              </p>
            </div>
            <div
              className="message_cont"
              onClick={() => {
                this.setState({ popupStatuses: popupView.note }); //쪽지
              }}
            >
              <p>쪽지 :</p>
              <p style={{ animationDuration: "0s" }}>{this.state.noteCount}</p>
            </div>
            <button
              type="button"
              className="logout_btn"
              onClick={() => {
                this.props.tryLoginOut();
              }}
            >
              <img src="images/icon/logout_check.png" alt="로그아웃" /> 로그아웃
            </button>
          </div>
        </>
      );
    };

    const RenderLoginMobile = () => {
      if (authenticated === false) {
        return (
          <>
            <div
              className="mobile_al"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <input
                type="text"
                placeholder="아이디"
                onChange={({ target: { value } }) =>
                  this.setState({ ID: value })
                }
                style={{
                  width: "120px",
                  height: "35px",
                  fontWeight: 700,
                  borderRadius: "3px",
                  marginLeft: "4px",
                  marginBottom: "4px",
                }}
              />
              <input
                type="password"
                placeholder="비밀번호"
                onChange={({ target: { value } }) =>
                  this.setState({ PW: value })
                }
                style={{
                  width: "120px",
                  height: "35px",
                  fontWeight: 700,
                  borderRadius: "3px",
                  marginLeft: "4px",
                  marginBottom: "4px",
                }}
              />
              <button
                type="submit"
                className="btn_sign_in"
                onClick={() => {
                  if (
                    this.state.ID == null ||
                    this.state.ID == "" ||
                    this.state.PW == "" ||
                    this.state.PW == null
                  ) {
                    return;
                  }
                  this.props.tryLogin(this.state.ID, this.state.PW);
                }}
                style={{
                  height: "35px",
                  fontWeight: 700,
                  borderRadius: "3px",
                  marginLeft: "4px",
                  marginBottom: "4px",
                }}
              >
                로그인
              </button>
              <button
                type="button"
                className="btn_sign_up"
                onClick={() => {
                  this.setState({ popupStatuses: popupView.reg });
                }}
                style={{
                  height: "35px",
                  fontWeight: 700,
                  borderRadius: "3px",
                  marginLeft: "4px",
                  marginBottom: "4px",
                }}
              >
                회원가입
              </button>
            </div>
          </>
        );
      }

      return (
        <>
          <div
            className="mobile_al"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <div>
              <img
                className="level"
                src="https://resource.fdsigaming.com/uploads/level/level_image1548826021.png"
                alt=""
              />
              <p>{this.props.user.id}</p>
            </div>
            <div
              onClick={() => {
                this.setState({ popupStatuses: popupView.note }); //
              }}
            >
              <i className="fa fa-envelope"></i>
              <p>{this.state.noteCount}</p>
            </div>
            <div>
              <i className="fa fa-krw"></i>
              <p className="player-balance">
                {ConverMoeny(this.props.user.balance)} 원
              </p>
            </div>
            <div>
              <i className="fa fa-krw"></i>
              <p className="player-point">
                {ConverMoeny(this.props.user.point)} 원
              </p>
            </div>

            <div
              className="logout_btn"
              onClick={() => {
                this.props.tryLoginOut();
              }}
            >
              <i className="fa fa-sign-out"></i>
              <p>로그아웃</p>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <div className="main_nav">
          <div className="top">
            <div className="mnt_cont">
              <a href="/" className="logo">
                <div className="light">
                  <img src="images/light.png" alt="logo" />
                </div>
                <div className="logo_cont">
                  <div className="c-c">
                    <img className="circle" src="images/circle.png" alt="" />
                    <img className="card" src="images/card.png" alt="" />
                  </div>
                  <img className="ol" src="images/online.png" alt="" />
                  <img className="ol on" src="images/online_on.png" alt="" />
                  <img className="casino" src="images/casino.png" alt="" />
                </div>
              </a>
              <div className="menu">
                <div className="left">
                  <button className="mobile_menu">
                    <i className="fa fa-navicon"></i>
                  </button>
                  <img
                    className="mobile_support"
                    src="images/mobile_support.png"
                    alt=""
                  />
                  <div className="sc_btns">
                    <button
                      onClick={() => {
                        this.props.updateContentView("casino");
                      }}
                    >
                      <img src="images/casino_btn.png" alt="" />
                      <img src="images/casino_btn_on.png" alt="" />
                    </button>
                    <button
                      onClick={() => {
                        this.props.updateContentView("slot");
                      }}
                    >
                      <img src="images/slot_btn.png" alt="" />
                      <img src="images/slot_btn_on.png" alt="" />
                    </button>
                  </div>
                </div>
                <div className="right">{RenderLogin()}</div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="mnb_cont">
              <div>
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.deposit }); //
                  }}
                >
                  <img src="images/deposit.png" alt="" />
                  <img src="images/deposit_on.png" alt="" />
                </button>
                <img className="mnb_border" src="images/mnb_border.png" />
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.withdraw }); //
                  }}
                >
                  <img src="images/withdraw.png" alt="" />
                  <img src="images/withdraw_on.png" alt="" />
                </button>
                <img className="mnb_border" src="images/mnb_border.png" />
              </div>
              <div className={this.props.activeHelp ? "neon" :""}>
                <img className="mnb_border" src="images/mnb_border.png" />
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //
                  }}
                >
                  <img src="images/notice.png" alt="" />
                  <img src="images/notice_on.png" alt="" />
                </button>
                {/* <img className="mnb_border" src="images/mnb_border.png" />
                <button type="button">
                  <img src="images/event.png" alt="" />
                  <img src="images/event_on.png" alt="" />
                </button> */}
              </div>
            </div>
          </div>
          <div className="nav_deco"></div>
        </div>

        <div className="sidenav_main">
          <div className="sidenav_main_cont">
            <div className="sidenav_head"></div>
            {RenderLoginMobile()}

            <div className="cont m-casino">
              <span>
                <img src="images/casino_sn.png" />
                <a>
                  라이브카지노 <i className="fa fa-angle-down"></i>
                </a>
                <p>CASINO</p>
              </span>
              <i className="fa fa-angle-down mobile_dd_i"></i>
              <div className="dropdowns">
                <div className="dd_main">{this.RenderCasinoGame()}</div>
              </div>
            </div>
            <div className="cont m-slot">
              <span>
                <img src="images/slot_sn.png" />
                <a>
                  슬롯게임 <i className="fa fa-angle-down"></i>
                </a>
                <p>SLOT</p>
              </span>
              <i className="fa fa-angle-down mobile_dd_i"></i>
              <div className="dropdowns">
                <div className="dd_main">{this.RenderSlotList()}</div>
              </div>
            </div>
            <div className="cont m-deposit">
              <span>
                <img src="images/deposit_sn.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.deposit }); //
                  }}
                >
                  입금신청
                </a>
                <p>DEPOSIT</p>
              </span>
            </div>
            <div className="cont m-withdraw">
              <span>
                <img src="images/withdraw_sn.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.withdraw }); //
                  }}
                >
                  출금신청
                </a>
                <p>WITHDRAW</p>
              </span>
            </div>
            <div className="cont m-withdraw">
              <span>
                <img src="images/withdraw_sn.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.point }); //
                  }}
                >
                  금고
                </a>
                <p>POINT</p>
              </span>
            </div>
            <div className="cont m-withdraw">
              <span>
                <img src="images/withdraw_sn.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.comp }); //
                  }}
                >
                  콤프
                </a>
                <p>COMP</p>
              </span>
            </div>
            <div className="cont m-board">
              <span>
                <img src="images/event_sn.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //
                  }}
                >
                  공지사항
                </a>
                <p>NOTICE</p>
              </span>
            </div>
            <div className="cont m-board">
              <span>
                <img src="images/event_sn.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.user }); //
                  }}
                >
                  마이페이지
                </a>
                <p>USER</p>
              </span>
            </div>
            <div className="cont m-board">
              <span>
                <img src="images/caution.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.help }); //
                  }}
                >
                  고객센터
                </a>
                <p>HELP</p>
              </span>
            </div>
            <div className="cont m-board">
              <span>
                <img src="images/caution.png" />
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.note }); //
                  }}
                >
                  쪽지
                </a>
                <p>NOTE</p>
              </span>
            </div>
          </div>
        </div>

        <div className="main_banner">
          <div className="mbanner_cont">
            <div className="center">
              <img src="images/casino1.png" alt="" />
              <img src="images/casino2.png" alt="" />
              <div
                className="mb_carousel carousel"
                data-ride="carousel"
                data-interval="3000"
                data-pause="false"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target=".mb_carousel"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target=".mb_carousel" data-slide-to="1"></li>
                  <li data-target=".mb_carousel" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="item active">
                    <p>Welcome to OnlineCasino !!</p>
                  </div>
                  <div className="item">
                    <p>Welcome to OnlineCasino !!</p>
                  </div>
                  <div className="item">
                    <p>Welcome to OnlineCasino !!</p>
                  </div>
                </div>
              </div>
              <img src="images/slot1.png" alt="" />
              <img src="images/slot2.png" alt="" />
            </div>
            <img className="coins" src="images/coins.png" alt="" />
          </div>
          <div className="curtain"></div>
        </div>

        <div className="main_sc_btn">
          <div className="cont">
            <div>
              <button data-tilt className="msc_btn">
                <img src="images/main_casino_on.png" alt="" />
                {/* <img src="images/main_casino_on.png" alt="" />
                <img src="images/main_casino_over.png" alt="" /> */}
              </button>
            </div>
            <div>
              <button data-tilt className="msc_btn ">
                <img src="images/main_slot_on.png" alt="" />
                {/* <img src="images/main_slot_on.png" alt="" />
                <img src="images/main_slot_over.png" alt="" /> */}
              </button>
            </div>
          </div>
        </div>

        {RenderPopup()}
        {/* {RendePopup()} */}
      </>
    );
  }
}
