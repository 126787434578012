import { Height } from "@material-ui/icons";
import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

interface Props {
  gameCode: string;
  gameName: string;
  gameEName: string;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGame = (info: any, index: any) => {
    return (
      <>
        <div
          className="slot_sp_game"
          data-tilt=""
          onClick={() => {
            this.handleOpenSlot(info.code, info.apiCompany)
          }}
        >
          <img src={info.imgUrl} alt="thumbnail" />
          <div className="overlay">
            <button type="button">PLAY</button>
          </div>
          <div className="foot">
            <p>{info.nameKo}</p>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="slotListModal modal fade in"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="slot_list_modal modal-dialog">
                <div className="slot_subpg">
                  <div className="slot_view">
                    <div className="header">
                      <p className="slot_name title">{this.props.gameName}</p>
                      <button
                        type="button"
                        data-dismiss="modal"
                        onClick={() => {
                          this.props.handleClose();
                        }}
                      ></button>
                    </div>
                    <div className="nav">
                      <img src="images/bg/slot_sp_deco.png" />
                    </div>

                    <div className="body" style={{ textAlign: "left" }}>
                      <div className="center slot-content po-game">
                        {this.state.games.map((row: any, index: any) =>
                          this.RenderGame(row, index)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
