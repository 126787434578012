import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { UserService } from "../service/user.service";
import { ConvertDate, HelpStatus } from "../utility/help";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            답변
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_help_message_read(row._id);
            setOpen(!open);
          }}
          style={{ color: "#ffffff" }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {HelpStatus(row.status)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  helps: any;
  mode: string;
  title: string;
  contents: string;
  status: string;
  regdate: string;
  ref: string;
  maxCount: number;
  index: number;
  no: string;
  page: number;
}

export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: helpView.list,
      title: "",
      contents: "",
      status: "",
      regdate: "",
      ref: "",
      maxCount: 0,
      index: 0,
      no: "",
      page: 1,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log("## this.userService.get_help_list");
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps, maxCount: data.maxCount });
      }
    });
  };

  prevHelp = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.helps.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            status: info.status,
            ref: info.ref != null ? info.ref.contents : "",
            index: index,
            no: info.no,
          });
        }
      });
    }
  };

  nextHelp = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.helps.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            status: info.status,
            ref: info.ref != null ? info.ref.contents : "",
            index: index,
            no: info.no,
          });
        }
      });
    }
  };

  handleSaveHelp = (title: string, contents: string) => {
    if (title == "") {
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }
    if (contents == "") {
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    this.props.handleClose();
  };

  render() {
    let helps: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.helps.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        helps.push(info);
      }
    });

    console.log("## helps");
    console.log(helps);

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderRef = () => {
      if (this.state.ref != null && this.state.ref !== "") {
        return (
          <Box margin={1}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color={"secondary"}
            >
              답변
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: this.state.ref }}></div>
          </Box>
        );
      }
    };

    const RenderList = () => {
      if (this.state.mode !== helpView.list) {
        return <></>;
      }
      return (
        <>
          <table className="board_table notice_table">
            <colgroup>
              <col width="10%" />
              <col width="50%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              {helps.map((info: any, index: any) => (
                <>
                  <tr
                    onClick={() => {
                      service.do_help_message_read(info._id);
                      this.setState({
                        title: info.title,
                        contents: info.contents,
                        regdate: info.regdate,
                        status: info.status,
                        ref: info.ref != null ? info.ref.contents : "",
                        mode: helpView.view,
                        index: index,
                        no: info.no,
                      });
                    }}
                  >
                    <td className="text-center">{info.no}</td>
                    <td className="text-left">{info.title}</td>
                    <td className="text-center">{ConvertDate(info.regdate)}</td>
                    <td className="text-center">{HelpStatus(info.status)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <ul className="pagination inquiry">
            <li>
              <a
                className="next"
                onClick={() => {
                  this.setState({
                    page: 1,
                  });
                }}
              >
                <span aria-hidden="true">«</span>
              </a>
            </li>{" "}
            {pages.map((page: any) => {
              if (page.no === this.state.page) {
                return (
                  <>
                    <li>
                      <a
                        className="active"
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <li>
                      <a
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              }
            })}
            <li>
              <a
                className="next"
                onClick={() => {
                  this.setState({
                    page: totPage,
                  });
                }}
              >
                <span aria-hidden="true">»</span>
              </a>
            </li>{" "}
          </ul>

          <div className="board_btn_grp">
            <button
              type="button"
              className="go_elist_btn"
              onClick={() => {
                this.setState({
                  mode: helpView.write,
                  title: "",
                  contents: "",
                });
              }}
              style={{ float: "revert" }}
            >
              1:1문의
            </button>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <>
          <table className="board_table">
            <colgroup>
              <col width="10%" />
              <col width="50%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              <tr className="active">
                <td className="text-center">{this.state.no}</td>
                <td className="text-left">{this.state.title}</td>
                <td className="text-center">
                  {ConvertDate(this.state.regdate)}
                </td>
                <td className="text-center">{HelpStatus(this.state.status)}</td>
              </tr>
            </tbody>
          </table>
          <div className="view_event">
            <div
              className="scroll_cont"
              style={{ textAlign: "left", color: "#fff" }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: this.state.contents }}
              ></span>

              {this.state.ref !== undefined && (
                <>
                  <br />
                  <br />
                  <p>[답변]</p>
                  <span
                    dangerouslySetInnerHTML={{ __html: this.state.ref }}
                  ></span>
                </>
              )}
            </div>
          </div>
          <div className="board_btn_grp">
            <button
              type="button"
              className="go_elist_btn"
              onClick={() => {
                this.setState({
                  mode: helpView.list,
                });
              }}
            >
              목록가기
            </button>

            <button
              type="button"
              onClick={() => {
                this.prevHelp(this.state.index - 1);
              }}
            >
              이전글보기
            </button>
            <button
              type="button"
              onClick={() => {
                this.nextHelp(this.state.index + 1);
              }}
            >
              다음글보기
            </button>
          </div>
        </>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <>
          <div className="view_event">
            <div className="form-group" style={{ float: "left" }}>
              <div>
                <p>
                  <i className="fa fa-angle-right"></i> 제목
                </p>
              </div>
              <div>
                <input
                  className="form-control w400"
                  placeholder="제목을 입력 하세요."
                  onChange={(e) =>
                    this.setState({
                      title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group" style={{ float: "left" }}>
              <div>
                <p>
                  <i className="fa fa-angle-right"></i> 내용
                </p>
              </div>
              <div>
                <textarea
                  className="form-control w400"
                  style={{ width: "500px", height: "300px" }}
                  rows={4}
                  cols={20}
                  maxLength={300}
                  placeholder="내용을 입력하세요"
                  onChange={(e) =>
                    this.setState({
                      contents: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="board_btn_grp">
            <button
              type="button"
              className="go_elist_btn"
              onClick={() => {
                this.handleSaveHelp(this.state.title, this.state.contents);
              }}
            >
              작성완료
            </button>

            <button
              type="button"
              onClick={() => {
                this.setState({
                  mode: helpView.list,
                });
              }}
            >
              취소
            </button>
          </div>

          {/* <div className="con_box10">
            <table
              style={{ width: "98.5%", border: "0", marginLeft: "10px" }}
              cellSpacing={0}
              cellPadding={0}
            >
              <tbody>
                <tr>
                  <td className="write_title">제목</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      style={{ width: "500px" }}
                      size={30}
                      placeholder="제목을 입력 하세요."
                      onChange={(e) =>
                        this.setState({
                          title: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td className="write_title">내용</td>
                  <td className="write_basic">
                    <textarea
                      className="input1"
                      style={{ width: "500px", height: "300px" }}
                      rows={4}
                      cols={20}
                      maxLength={300}
                      placeholder="내용을 입력하세요"
                      onChange={(e) =>
                        this.setState({
                          contents: e.target.value,
                        })
                      }
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="btn_wrap_center">
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.handleSaveHelp(
                        this.state.title,
                        this.state.contents
                      );
                    }}
                  >
                    <span className="btn2_1">작성 완료</span>
                  </a>

                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        mode: helpView.list,
                      });
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    <span className="btn2_1">취소</span>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </>
      );
    };
    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="noticeModal modal fade in"
              style={{ display: "block" }}
            >
              <div className="board_modal rev_modal modal-dialog">
                <div className="header">
                  <p>
                    <img src="images/icon/crown.png" /> 고객센터
                  </p>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                </div>
                <div className="modal_body">
                  <div className="board_head">
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.help);
                      }}
                    >
                      공지사항
                    </button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.user);
                      }}
                    >
                      마이페이지
                    </button>
                    <button className="active">고객센터</button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.note);
                      }}
                    >
                      쪽지
                    </button>
                  </div>
                  <div className="board_event">
                    <div className="board_event_list notice-section">
                      {RenderList()}
                      {RenderView()}
                      {RenderWrite()}
                    </div>
                    <div className="board_event_see notice-view-section"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              id="sub_pop1"
              className="popup_style04 popup_none popup_content"
              style={{
                opacity: "1",
                visibility: "visible",
                display: "inline-block",
                outline: "none",
                transition: "all 0.3s ease 0s",
                textAlign: "left",
                position: "relative",
                verticalAlign: "middle",
              }}
            >
              <div className="popup_wrap">
                <div className="close_box">
                  <a
                    href="javascript:;"
                    className="sub_pop1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
                <div className="popupbox">
                  <div className="popup_tab_wrap">
                    <ul className="popup_tab popup_tab1">
                      <li className="tab1 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.deposit);
                          }}
                        >
                          <span>입금신청</span>
                        </a>
                      </li>
                      <li className="tab2">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.withdraw);
                          }}
                        >
                          <span>출금신청</span>
                        </a>
                      </li>
                      <li className="tab3 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.point);
                          }}
                        >
                          <span>금고</span>
                        </a>
                      </li>
                      <li className="tab4 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.comp);
                          }}
                        >
                          <span>콤프</span>
                        </a>
                      </li>
                      <li className="tab5 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.notice);
                          }}
                        >
                          <span>공지사항</span>
                        </a>
                      </li>
                      <li className="tab6">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.user);
                          }}
                        >
                          <span>마이페이지</span>
                        </a>
                      </li>
                      <li className="tab7 sk_tab_active_01">
                        <a href="javascript:;">
                          <span>고객센터</span>
                        </a>
                      </li>
                      <li className="tab8 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.note);
                          }}
                        >
                          <span>쪽지</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                      <div className="title1">고객센터 </div>

                      <div className="contents_in">
                        {RenderList()}
                        {RenderView()}
                        {RenderWrite()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
