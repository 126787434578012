import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

export enum noticeView {
  none = "none",
  list = "list",
  view = "view",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2 className="list_notice1">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => setOpen(!open)}
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {ConvertDate(row.row)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="list_notice2"
        ></CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  notices: any;
  mode: string;
  title: string;
  contents: string;
  date: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
}

export class Notice extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      notices: [],
      mode: noticeView.list,
      title: "",
      contents: "",
      date: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({
          notices: data.notices,
          maxCount: data.maxCount,
        });
      }
    });
  };

  prevNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.regdate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  nextNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.regdate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  render() {
    let notices: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.notices.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        notices.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noticeView.list) {
        return <></>;
      }
      return (
        <>
          <table className="board_table notice_table">
            <colgroup>
              <col width="10%" />
              <col width="50%" />
              <col width="40%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
              </tr>
            </thead>
            <tbody>
              {notices.map((info: any, index: any) => (
                <>
                  <tr
                    onClick={() => {
                      this.setState({
                        title: info.title,
                        contents: info.contents,
                        date: info.regdate,
                        index: index,
                        no: info.no,
                        mode: noticeView.view,
                      });
                    }}
                  >
                    <td className="text-center">{info.no}</td>
                    <td className="text-left">{info.title}</td>
                    <td className="text-center">{ConvertDate(info.regdate)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <ul className="pagination inquiry">
            <li>
              <a
                className="next"
                onClick={() => {
                  this.setState({
                    page: 1,
                  });
                }}
              >
                <span aria-hidden="true">«</span>
              </a>
            </li>{" "}
            {pages.map((page: any) => {
              if (page.no === this.state.page) {
                return (
                  <>
                    <li>
                      <a
                        className="active"
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <li>
                      <a
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              }
            })}
            <li>
              <a
                className="next"
                onClick={() => {
                  this.setState({
                    page: totPage,
                  });
                }}
              >
                <span aria-hidden="true">»</span>
              </a>
            </li>{" "}
          </ul>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noticeView.view) {
        return <></>;
      }
      return (
        <>
          <table className="board_table">
            <colgroup>
              <col width="10%" />
              <col width="50%" />
              <col width="40%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
              </tr>
            </thead>
            <tbody>
              <tr className="active">
                <td className="text-center">{this.state.no}</td>
                <td className="text-left">{this.state.title}</td>
                <td className="text-center">{ConvertDate(this.state.date)}</td>
              </tr>
            </tbody>
          </table>
          <div className="view_event">
            <div className="scroll_cont">
              <div
                dangerouslySetInnerHTML={{ __html: this.state.contents }}
              ></div>
            </div>
          </div>
          <div className="board_btn_grp">
            <button
              type="button"
              className="go_elist_btn"
              onClick={() => {
                this.setState({
                  mode: noticeView.list,
                });
              }}
            >
              목록가기
            </button>

            <button
              type="button"
              onClick={() => {
                this.prevNotice(this.state.index - 1);
              }}
            >
              이전글보기
            </button>
            <button
              type="button"
              onClick={() => {
                this.nextNotice(this.state.index + 1);
              }}
            >
              다음글보기
            </button>
          </div>
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="noticeModal modal fade in"
              style={{ display: "block" }}
            >
              <div className="board_modal rev_modal modal-dialog">
                <div className="header">
                  <p>
                    <img src="images/icon/crown.png" /> 공지사항
                  </p>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                </div>
                <div className="modal_body">
                  <div className="board_head">
                    <button className="active">공지사항</button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.user);
                      }}
                    >
                      마이페이지
                    </button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.help);
                      }}
                    >
                      고객센터
                    </button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.note);
                      }}
                    >
                      쪽지
                    </button>
                  </div>
                  <div className="board_event">
                    <div className="board_event_list notice-section">
                      {RenderList()}
                      {RenderView()}
                    </div>
                    <div className="board_event_see notice-view-section"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
