import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";
import { Companylist } from "./companylist";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import Marquee from "react-fast-marquee";
import { isBrowser, isMobile } from "react-device-detect";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  gameView: string;
  updateUser: () => any;
  updateContentView: (view: string) => any;
  contentView: string;
  
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  gameEName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  widhdraws: any;
  yesterdayStr: string;

  favSlot1: boolean;
  favSlot2: boolean;
  favSlot3: boolean;
  favSlot4: boolean;
  favSlot5: boolean;
  favSlot6: boolean;
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.casino,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      gameCode: "",
      gameName: "",
      gameEName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
      yesterdayStr: "",

      favSlot1: false,
      favSlot2: false,
      favSlot3: false,
      favSlot4: false,
      favSlot5: false,
      favSlot6: false,
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        console.log(s.slot);
        this.setState({ slots: s.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });

        console.log(this.state.casionos);
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일";

    this.setState({ yesterdayStr: yesterdayStr });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlotList = () => {
    if (this.props.contentView === "slot") {
      return (
        <>
          {this.state.slots.map((info: any, index: any) => (
            <div
              data-tilt=""
              className="slot_game sc_game"
              style={{
                willChange: "transform",
                transform: "perspective(1500px) rotateX(0deg) rotateY(0deg)",
              }}
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (this.props.authenticated === false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({
                            mode: Mode.none,
                          });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.setState({ gameCode: info.code });
                  this.setState({ gameName: info.nameKo });
                  this.setState({ gameEName: info.nameEn });
                  this.setState({ mode: Mode.slot });
                  // this.handleSlotGames(info.code);
                  this.setState({ popupStatuses: popupView.game });
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <img src={info.imgUrl} alt="" />
              <span>
                <p>{info.nameKo}</p>
                <button type="button">PLAY</button>
              </span>
              <div
                className="js-tilt-glare"
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  pointerEvents: "none",
                }}
              >
                <div
                  className="js-tilt-glare-inner"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    backgroundImage:
                      "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
                    width: "488px",
                    height: "488px",
                    transform: "rotate(180deg) translate(-50%, -50%)",
                    transformOrigin: "0% 0%",
                    opacity: "0",
                  }}
                ></div>
              </div>
            </div>
          ))}
        </>
      );
    }

    return (
      <>
        {this.state.casionos.map((info: any, index: any) => (
          <div
            data-tilt=""
            className="casino_game sc_game"
            style={{
              willChange: "transform",
              transform:
                "transform; transform: perspective(1500px) rotateX(0deg) rotateY(0deg)",
            }}
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated === false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          popupStatuses: popupView.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.handleOpenSlot(info.code, info.gameCompany);
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <img src={info.mobileImg} alt="" />
            <span>
              <p>{info.nameKo}</p>
              <button type="button">PLAY</button>
            </span>
            <div
              className="js-tilt-glare"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                pointerEvents: "none",
              }}
            >
              <div
                className="js-tilt-glare-inner"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  backgroundImage:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
                  width: "488px",
                  height: "488px",
                  transform: "rotate(180deg) translate(-50%, -50%)",
                  transformOrigin: "0% 0%",
                  opacity: "0",
                }}
              ></div>
            </div>
          </div>
        ))}
      </>
    );
  };

  RenderCasinoGame = () => {
    return (
      <>
        {this.state.casionos.map((info: any, index: any) => (
          <div
            data-tilt=""
            className="casino_game sc_game"
            style={{
              willChange: "transform",
              transform:
                "transform; transform: perspective(1500px) rotateX(0deg) rotateY(0deg)",
            }}
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          popupStatuses: popupView.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.handleOpenSlot(info.code, info.gameCompany);
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <img src={info.mobileImg} alt="" />
            <span>
              <p>{info.nameKo}</p>
              <button type="button">PLAY</button>
            </span>
            <div
              className="js-tilt-glare"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                pointerEvents: "none",
              }}
            >
              <div
                className="js-tilt-glare-inner"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  backgroundImage:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
                  width: "488px",
                  height: "488px",
                  transform: "rotate(180deg) translate(-50%, -50%)",
                  transformOrigin: "0% 0%",
                  opacity: "0",
                }}
              ></div>
            </div>
          </div>
        ))}
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    // let bestSlots: any = [];
    // this.state.slots.map((info: any, index: any) => {
    //   if (index < 6) {
    //     bestSlots.push(info);
    //   }
    // });

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
            gameEName={this.state.gameEName}
          ></Companylist>
        );
      }

      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      return <div></div>;
    };

    return (
      <>
          <div className="main_body">
                    <div className="main_casino_cont" style={{ display: "block" ,  marginBottom : '50px'}}>

                {
                  this.props.contentView === Mode.slot ? 
                  <img src="/images/btn_live_slot_active.png" onClick={()=> this.props.updateContentView(Mode.slot)} style={{width: isMobile ?"100%" : '', }} />
                  :
                  <img src="/images/btn_live_slot.png" onClick={()=> this.props.updateContentView(Mode.slot)}  style={{width: isMobile ?"100%" : '', }}/>
                }
                {
                  this.props.contentView === Mode.casino ? 
                  <img src="/images/btn_live_casino_active.png"  onClick={()=> this.props.updateContentView(Mode.casino)} style={{width: isMobile ?"100%" : '', }}/> 
                  :
                    <img src="/images/btn_live_casino.png"  onClick={()=> this.props.updateContentView(Mode.casino)} style={{width: isMobile ?"100%" : '', }}/> 
                }
            </div>
          </div>
          {
            this.props.contentView === Mode.casino && (
                  <div className="main_body">
                    <div className="main_casino_cont" style={{ display: "block" }}>
                      {this.RenderCasinoGame()}
                    </div>
                  </div>
        
            )
          }

          {
            this.props.contentView === Mode.slot && (
              <div className="main_body">
              <div className="main_slot_cont" style={{ display: "block" }}>
                {this.RenderSlotList()}
              </div>
            </div>
    
            
            )
          }

        {RenderPopup()}
      </>
    );
  }
}
