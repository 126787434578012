import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

import { ShareMenu } from "./share_menu";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
  point: string;
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: "0",
      point: "0",
    };
  }

  handleChangeToBalance = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "벨런스",
        message: "벨런스를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "벨런스",
      message: "보유머니를 입금 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_balance_to_point(this.state.balance)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "벨런스",
                    message: "벨런스를 금고머니로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유머니를 초과하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point) <= 0) {
      confirmAlert({
        title: "금고머니",
        message: "금고머니를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "금고머니",
      message: "금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(this.state.point)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "금고머니",
                    message: "금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유금을 초과 하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    console.log(this.props.user);
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="depositModal modal fade in"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="dep_with_modal rev_modal modal-dialog">
                <div className="header">
                  <p>
                    <img src="images/icon/crown.png" /> 금고
                  </p>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                </div>
                <div className="modal_body">
                  <div className="board_head">
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.deposit);
                      }}
                    >
                      입금신청
                    </button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw);
                      }}
                    >
                      출금신청
                    </button>
                    <button style={{ width: "120px" }} className="active">
                      금고
                    </button>
                    <button
                      style={{ width: "120px" }}
                      onClick={() => {
                        this.props.handleChange(popupView.comp);
                      }}
                    >
                      콤프
                    </button>
                  </div>

                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 보유머니
                      </p>
                    </div>
                    <div>
                      <p>{ConverMoeny(this.props.user.balance)} 원</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 금고머니
                      </p>
                    </div>
                    <div>
                      <p>{ConverMoeny(this.props.user.point)} 원</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 예금주명
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        readOnly
                        value={this.props.user.bankowner}
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <p>
                        <i className="fa fa-angle-right"></i> 회원은행
                      </p>
                    </div>
                    <div>
                      <input
                        className="form-control w400"
                        readOnly
                        value={this.props.user.bankname}
                      />
                      <ul className="parsley-errors-list"></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              id="sub_pop1"
              className="popup_style04 popup_none popup_content"
              style={{
                opacity: "1",
                visibility: "visible",
                display: "inline-block",
                outline: "none",
                transition: "all 0.3s ease 0s",
                textAlign: "left",
                position: "relative",
                verticalAlign: "middle",
              }}
            >
              <div className="popup_wrap">
                <div className="close_box">
                  <a
                    href="javascript:;"
                    className="sub_pop1_close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="images/popup_close.png" />
                  </a>
                </div>
                <div className="popupbox">
                  <div className="popup_tab_wrap">
                    <ul className="popup_tab popup_tab1">
                      <li className="tab1 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.deposit);
                          }}
                        >
                          <span>입금신청</span>
                        </a>
                      </li>
                      <li className="tab2">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.withdraw);
                          }}
                        >
                          <span>출금신청</span>
                        </a>
                      </li>
                      <li className="tab3 sk_tab_active_01">
                        <a href="javascript:;">
                          <span>금고</span>
                        </a>
                      </li>
                      <li className="tab4 ">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.comp);
                          }}
                        >
                          <span>콤프</span>
                        </a>
                      </li>
                      <li className="tab5">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.notice);
                          }}
                        >
                          <span>공지사항</span>
                        </a>
                      </li>
                      <li className="tab6">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.user);
                          }}
                        >
                          <span>마이페이지</span>
                        </a>
                      </li>
                      <li className="tab7">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.help);
                          }}
                        >
                          <span>고객센터</span>
                        </a>
                      </li>
                      <li className="tab8">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.props.handleChange(popupView.note);
                          }}
                        >
                          <span>쪽지</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                      <div className="title1">금고 </div>
                      <div className="con_box10">
                        <div className="money">
                          <ul>
                            <li style={{ width: "250px", textAlign: "left" }}>
                              <img src="images/ww_icon.png" height="26" />{" "}
                              보유머니 :{" "}
                              <span className="font05" id="myWalletPop">
                                {ConverMoeny(this.props.user.balance)}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </li>
                            <li style={{ width: "250px", textAlign: "left" }}>
                              <img src="images/ww_icon2.png" height="26" />{" "}
                              금고머니 :{" "}
                              <span className="font05" id="myCasPop">
                                {ConverMoeny(this.props.user.point)}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </li>
                            <li></li>
                          </ul>
                        </div>
                      </div>

                      <div className="con_box10">
                        <table
                          style={{ width: "100%", border: 0 }}
                          cellSpacing={0}
                          cellPadding={0}
                          className="write_title_top"
                        >
                          <tbody>
                            <tr>
                              <td className="write_title">입금신청</td>

                              <td className="write_basic">
                                <input
                                  className="input1"
                                  size={30}
                                  placeholder="0"
                                  value={this.state.point}
                                  onFocus={() => this.setState({ point: "" })}
                                  onChange={(e) =>
                                    this.setState({
                                      point: e.target.value,
                                    })
                                  }
                                />{" "}
                                <a
                                  href="javascript:;"
                                  onClick={this.handleChangeToBalance}
                                >
                                  <span className="btn1_1">입금하기</span>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td className="write_title">출금신청</td>

                              <td className="write_basic">
                                <input
                                  className="input1"
                                  size={30}
                                  placeholder="0"
                                  value={this.state.point}
                                  onFocus={() => this.setState({ point: "" })}
                                  onChange={(e) =>
                                    this.setState({
                                      point: e.target.value,
                                    })
                                  }
                                />{" "}
                                <a
                                  href="javascript:;"
                                  onClick={this.handleChangeToPoint}
                                >
                                  <span className="btn1_1">출금하기</span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
