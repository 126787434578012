import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)``;

const CustomTableCell2 = styled(TableCell)``;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className="ng-scope" key={row._id}>
        <CustomTableCell2 className="width12 text-center ng-binding">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_read_note(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_read_note(row._id);
            setOpen(!open);
          }}
          className="text-left width70"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {row.isRead === "y" ? "확인" : "미확인"}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClosePopup: () => any;
  handleChange: (viewName: string) => any;
  handleClose: () => any;

  note: any;
}

interface State {
  helps: any;
  note: any;
  mode: string;
  regdate: string;
  title: string;
  contents: string;
  isRead: boolean;
  text: string;
  status: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
}

export class Note extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      note: [],
      mode: noteView.list,
      title: "",
      contents: "",
      regdate: "",
      isRead: false,
      text: "",
      status: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    this.userService.get_user_note().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        if (data.count > 0) {
          this.setState({
            note: data.note,
          });
        }
      }
    });
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  prevNote = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          service.do_read_note(info._id);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  nextNote = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          service.do_read_note(info._id);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  render() {
    let note: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.note.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        note.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noteView.list) {
        return <></>;
      }
      return (
        <>
          <table className="board_table notice_table">
            <colgroup>
              <col width="10%" />
              <col width="50%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              {note.map((info: any, index: any) => (
                <>
                  <tr
                    onClick={() => {
                      service.do_read_note(info._id);
                      this.setState({
                        title: info.title,
                        contents: info.contents,
                        regdate: info.regdate,
                        isRead: info.isRead,
                        text: info.text,
                        mode: noteView.view,
                        index: index,
                        no: info.no,
                        status: info.isRead === "y" ? "확인" : "미확인",
                      });
                    }}
                  >
                    <td className="text-center">{info.no}</td>
                    <td className="text-left">{info.title}</td>
                    <td className="text-center">{ConvertDate(info.regdate)}</td>
                    <td className="text-center">
                      {info.isRead === "y" ? "확인" : "미확인"}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <ul className="pagination inquiry">
            <li>
              <a
                className="next"
                onClick={() => {
                  this.setState({
                    page: 1,
                  });
                }}
              >
                <span aria-hidden="true">«</span>
              </a>
            </li>{" "}
            {pages.map((page: any) => {
              if (page.no === this.state.page) {
                return (
                  <>
                    <li>
                      <a
                        className="active"
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <li>
                      <a
                        onClick={() => {
                          this.setState({
                            page: page.no,
                          });
                        }}
                      >
                        {page.no}
                      </a>
                    </li>
                  </>
                );
              }
            })}
            <li>
              <a
                className="next"
                onClick={() => {
                  this.setState({
                    page: totPage,
                  });
                }}
              >
                <span aria-hidden="true">»</span>
              </a>
            </li>{" "}
          </ul>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <>
          <table className="board_table">
            <colgroup>
              <col width="10%" />
              <col width="50%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              <tr className="active">
                <td className="text-center">{this.state.no}</td>
                <td className="text-left">{this.state.title}</td>
                <td className="text-center">
                  {ConvertDate(this.state.regdate)}
                </td>
                <td className="text-center">{this.state.status}</td>
              </tr>
            </tbody>
          </table>
          <div className="view_event">
            <div className="scroll_cont">
              <div dangerouslySetInnerHTML={{ __html: this.state.text }}></div>
            </div>
          </div>
          <div className="board_btn_grp">
            <button
              type="button"
              className="go_elist_btn"
              onClick={() => {
                this.setState({
                  mode: noteView.list,
                });
              }}
            >
              목록가기
            </button>

            <button
              type="button"
              onClick={() => {
                this.prevNote(this.state.index - 1);
              }}
            >
              이전글보기
            </button>
            <button
              type="button"
              onClick={() => {
                this.nextNote(this.state.index + 1);
              }}
            >
              다음글보기
            </button>
          </div>
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="noticeModal modal fade in"
              style={{ display: "block" }}
            >
              <div className="board_modal rev_modal modal-dialog">
                <div className="header">
                  <p>
                    <img src="images/icon/crown.png" /> 쪽지
                  </p>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                </div>
                <div className="modal_body">
                  <div className="board_head">
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.note);
                      }}
                    >
                      공지사항
                    </button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.user);
                      }}
                    >
                      마이페이지
                    </button>
                    <button
                      onClick={() => {
                        this.props.handleChange(popupView.help);
                      }}
                    >
                      고객센터
                    </button>
                    <button className="active">쪽지</button>
                  </div>
                  <div className="board_event">
                    <div className="board_event_list notice-section">
                      {RenderList()}
                      {RenderView()}
                    </div>
                    <div className="board_event_see notice-view-section"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
